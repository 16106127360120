<template>
    <div>
        <div class="top_content bg">
            <div class="max_cont_width">
                <!-- <div class="top_logo flex_center_between_box1">
                    <div class="top_logo_img"><router-link to="/"><img src="../../assets/img/logo_white.png" /></router-link></div>
                    <div class="sigin" @click="loginPop()">Sign in</div>
                </div> -->
                <el-header class="max_width top_logo ">
                <router-link to="/">
               <!-- <img class="head_top_left phone_page_bg" src="../../assets/img/csiweb.png" alt="csi" /> -->
                <img class="top_logo_img" src="../../assets/img/logo_white.png" alt="csp" />
                
                </router-link>
                <span class="head_sign" @click="loginPop()" v-if="!loginStatus">
                    <div placement="top" width="0" popper-class="">
                        <!-- my Account -->
                        <div class="head_login sinin" slot="reference">
                            <span class="switch_txt" @click="loginPop">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                    </div>
                </span>
                <template v-else>
                    <el-popover placement="top" width="230" popper-class="pop_top_account">
                        <!-- my Account -->
                        <div class="head_login" :class="userRole == 'company' ? 'company_role' : 'person_role'" slot="reference">
                            <span class="switch_txt">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                        <div class="head_account">
                            <div class="top_usage flex_center_between_box">
                                <div class="usage_ic">
                                    <img src="../../assets/img/usage_ic.png" />
                                </div>
                                <div class="usage_prog">
                                    <div class="flex_center_between_box">
                                        <div class="usage_txt">Usage</div>
                                        <div class="usage_total">{{ account.used | FilterNum }} used</div>
                                    </div>
                                    <el-progress v-if="account.total > 0" :show-text="false" :stroke-width="5" define-back-color="#ffffff" :color="customColorMethod" :percentage="parseFloat(((1 - account.used / (account.total + account.used)) * 100).toFixed(2))"></el-progress>
                                    <div class="usage_used" style="display: flex; justify-content: space-between;" :class="{ parnterr: percentageerr }">  <div>{{ account.total | FilterNum }}/{{ account.used | FilterNum }}</div><div v-if="account.total==0 &&account.used==0">0</div><div v-else>BL: {{ parseFloat((account.total/(account.total + account.used))*100).toFixed(2)}}% </div></div>
                                    <!-- <div class="usage_used" :class="{ parnterr: percentageerr }">Balance:{{ account.total | FilterNum }}</div> -->
                                </div>
                            </div>
                            <div class="top_acc_menu">
                                <ul class="top_acc_menu_box">
                                    <li v-for="(menu, index) in accountMenuList" :key="index" class="userinfo-li">
                                        <router-link :to="menu.url" v-if="menu.type != 0" :class="{ parnterr: menu.name == 'Add Credit' && percentageerr }">{{ menu.name }}</router-link>
                                    </li>
                                    <li class="acc_signout" @click="signOutAccount">Sign out</li>
                                </ul>
                            </div>
                        </div>
                    </el-popover>
                </template>
            </el-header>
                <h2 class="top_title">Perform an instant search<br/>from over 400 million companies worldwide</h2>
                <h5 class="top_sub_title">CSI covers 100% of manufacturers and service companies. The search includes companies large and small</h5>
            </div>
        </div>
        <div class="our_story">
            <div class="max_cont_width flex_center_between_box1">
                <div class="story_left">
                    <div class="title_blue title_blue1">Our Story</div>
                    <div  class="txt_des">CSI’s foundations began with a penchant for servicing and strengthening the abilities for middle market and multinational clients in Asia.  Our roots are grounded with exceptional professionals and staff with over 100 years of experience in the B2B intelligence industry. It’s these roots and experiences that our passions in the B2B industry were born. We strive to provide our clients with best-in-class information and fill the void of global reliable information.</div>
                </div>
                <div  class="story_right">
                    <img src="../../assets/img/help/about_img1.png"/>
                </div>
            </div>
        </div>
        <div class="our_purpose bg">
            <div class="max_cont_width flex_center_between_box1">
                <div  class="purpose_left">
                    <img src="../../assets/img/help/about_img2.png"/>
                </div>
                <div class="purpose_right">
                    <div class="title_dack_blue title_dack_blue1">Our Purpose</div>
                    <div  class="txt_des">The CSI platform continues the devotion, vision and mission we’ve had from the beginning: to deliver B2B intelligence at the highest level of coverage and quality. Our goal is to provide the foremost B2B intelligence platform in the industry. With a data base of over 400 million companies in over 200 countries and a myriad of data points, we provide our clients with finely-tuned corporate evaluations on a macro level. We always strive to improve and expand the products we provide so our clients obtain the best possible value.</div>
                </div>
            </div>
        </div>
        <div class="our_date">
            <div class="max_cont_width flex_center_between_box1">
                <div class="date_left">
                    <div class="title_blue title_blue2">Our Data</div>
                    <div class="txt_des">CSI has built a worldwide data intelligence platform to provide users an instant access to B2B data. We work with our selected partners in various markets to source valuable public information including business records, websites, verified data, etc.  Our partners include industry-leading business information providers located around the world.
                        <div class="data_cont_m">CSI also developed our own Data Quality Index (DQI) to apply our strict data governance and quality standards. We apply the latest technologies and methodologies to build various statistical and analytical models to add value for our users.</div>
                    </div>
                </div>
                <div  class="date_right">
                    <img src="../../assets/img/help/about_img3.png"/>
                </div>
            </div>
        </div>
         <div class="corp bg">
            <div class="max_cont_width">
                <h2 class="cor_title">corporate<br/>analytics</h2>
            </div>
        </div>
        <div class="our_team bg">
            <div class="max_cont_width flex_center_between_box1">
                <div  class="team_left">
                    <img src="../../assets/img/help/about_img4.png"/>
                </div>
                <div class="team_right">
                    <div class="title_dack_blue">Our Team</div>
                    <div class="txt_des">CSI is guided by executives that have led organizations to the top echelons of industry, all of which have a passion for corporate analytics as the driver of empowerment and security for international trade. Our leadership crosses the spectrum of the B2B industry - experts in data collection, composition, verification, credit appraisal/analytics and marketing at the highest level of industry.</div>
                </div>
            </div>
        </div>
        <div class="contact_us">
            <div class="max_cont_width flex_center_between_box1">
                <div class="contact_left">
                    <div class="title_blue title_blue4">Contact Us</div>
                    <div class="contact_txt contact_m">Please let us know how we can help you meet your business requirements.  <div >Company Search Incorporated</div></div>
                  
                    <div class="contact_title_txt">Co. No.:  <span style="margin-left: 92px;">2017-000781824</span></div>
                    <!-- <div class="contact_txt contact_m"></div> -->
                    <div class="contact_title_txt" style="margin-bottom: 0;">Address:<span style="margin-left: 90px;">2619 Hibernia Street</span></div>
                    <div class="delase"> Dallas, TX 75204, USA</div>
                    <div class="contact_title_txt" >Phone:<span style="margin-left: 105px;">+1 (940) 455-2284</span></div>
                    <!-- <div class="contact_txt contact_m"></div> -->
                    <div class="contact_title_txt">General inquiries:     <a href="mailto:service@csibizinfo.com" class="link">service@csibizinfo.com</a> </div>
                    <!-- <div class="contact_txt contact_m">
                   
                    </div> -->
                    <div class="contact_title_txt">Service orders <a style="margin-left: 30px;" href="mailto:request@CSIbizInfo.com" class="link">request@csibizinfo.com </a></div>
                    <!-- <div class="contact_txt contact_m">  </div> -->
                </div>
                <div  class="contact_right">
                    <img src="../../assets/img/help/about_img5.png"/>
                </div>
            </div>
        </div>
        <Footer></Footer>

        <Login :loginAndCreatePop.sync='loginDialog'></Login>
    </div>
</template>
<script>
import { LoginOut } from '@/api/api.js';
import Login from '@/components/login'
import Footer from '@/components/footer'
import bus from '@/components/bus';

import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { Login,Footer },
    data() {
        return {
            loginDialog:false,//登录弹框
            code: '',
            imgUrl: require('../../assets/img/bg1.png'),
            searchOption: 'Search Company',
            show: 0,
            seachdoudong: false,
            percentageerr: false,
            account: { total: '', used: '' },
            loginStatus: false, //登录状态
            menuInfos: [],
            token: sessionStorage.getItem('token'),
            userRole: {},
        }
    },
    destroyed() {
        bus.$off('handleLogin');
    },
    created() {
        //登陆状态
        this.token ? (this.loginStatus = true) : (this.loginStatus = false);
        //登陆状态
        this.$bus.$on('loginabouttrue', loginCheck => {
            console.log(`loginCheck`, loginCheck);
            this.loginStatus = loginCheck;
            const user = JSON.parse(localStorage.getItem('userInfo'));
            this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
            //account
            this.getAccountMsg();
        });
        if (this.token) {
            const user = JSON.parse(localStorage.getItem('userInfo'));
            this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
            //account
            this.getAccountMsg();
        }
    },
    mounted() {
   
        this.padaun();
    },
    methods: {
        padaun() {
            if (this.account.total == 0) {
                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);
            }
        },
        signOutAccount() {
            const userCode = JSON.parse(sessionStorage.getItem('userInfo')).userInfo.userCode;
            LoginOut({ username: userCode }).then(res => {
                if (res.code) {
                    this.$message({
                        message: 'Exit successfully',
                        center: true,
                        type: 'success',
                    });
                    sessionStorage.clear();
                    window.location.href = '/';
                }
            });
        },
    
        // 控制积分进度条颜色
        customColorMethod(percentage) {
  
            if (percentage < 5 || this.account.total == 0) {
                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);
                return '#f56c6c';
            } else if (percentage < 10) {
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#e6a23c';
            } else {
                // console.log(`this.percentageerr`,this.percentageerr );
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#1290c9';
            }
        },
        //登录弹窗
        loginPop(){
            this.loginDialog=true
        },
        
    } 
}
</script>
<style lang="css" scoped>
.bg{
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.top_content{
    width: 100%;
    height: 708px;
    background-image: url(../../assets/img/help/about_bg1.png);
}  
.top_logo{
    padding-top: 30px;
}
.top_logo_img{
    width: 194px;
}
.sigin{
    font-size: 16px;
    color: #FFFFFF;
    line-height: 18px;
    cursor: pointer;
}
.cor_title,
.top_title,
.top_sub_title{
    text-align: center;
}
.top_title{
    font-size: 48px;
    font-family: "Arial Italic Bold";
    color: #FFFFFF;
    line-height: 64px;
    text-shadow: 0px 1px 4px #081B2D;
    margin-top: 71px;
    margin-bottom: 21px;
}
.top_sub_title{
    font-size: 16px;
    font-family: "Arial Italic";
    color: #FFFFFF;
    line-height: 31px;
}
.story_left{
    width: 582px;
}
.story_right img{
    width: 630px;
}
.title_dack_blue,
.title_blue{
    margin-bottom: 40px;
    height: 60px;
    font-size: 50px;
    font-family: "Arial Bold";
    line-height: 60px;
}
.title_blue{
    color: #1290C9;
}
.title_blue1{
    margin-top: 105px;
}
.title_dack_blue{
    color: #022955;
}
.title_dack_blue1{
    margin-top: 66px;
}
.txt_des{
    font-size: 20px;
    color: #022955;
    line-height: 38px;  
}
.our_team,
.our_purpose{
    width: 100%;
    height: 1050px;
    background-image: url(../../assets/img/help/about_bg2.png);
}
.purpose_left img{
    width: 762px;
    margin-top: 211px;
}
.date_left{
    width: 582px;
}
.date_right{
    margin-top: -80px;
}
.purpose_right{
    width: 487px;
}
.date_right img{
    width: 663px;
}
.data_cont_m{
    margin-top: 40px;
}
.corp{
    width: 100%;
    height: 760px;
    background-image: url(../../assets/img/help/about_bg3.png);
}
.cor_title{
    text-transform: uppercase;
    padding: 250px 0;
    font-size: 90px;
    color: #FFFFFF;
    line-height: 126px;
}
.team_left img{
    width: 648px;
    margin-top: 150px;
}
.contact_left,
.team_right{
    width: 582px;
}
.our_team{
    margin-top: -168px;
}
.contact_us{
    margin-bottom: 75px;
}
.contact_right img{
    width: 706px;
}
.contact_txt{
    font-size: 20px;
    line-height: 38px;
}
.contact_m{
    margin-bottom: 40px;
}
.contact_us .link{
    color: #1290C9;
    text-decoration: underline;
}
.contact_title_txt{
    margin-bottom: 10px;
    font-family:  "Arial Bold";
    font-size: 20px;
}
.flex_center_between_box1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.parnterr {
    color: red !important;
}
.product .search_input /deep/ .el-input__inner {
    border: none !important;
    padding-left: 0 !important;
    font-size: 16px;
}
.head_login {
    position: relative;
    margin-left: 30px;
    width: 98px;
    height: 34px;
    /* background-color: r(12,17,15); */
    background: #006a9a;
    background: #8ec9e4;
    /* box-shadow: inset 0px 1px 2px 0px #054562; */
    border-radius: 17px;
}
.head_login::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
}

.head_login .el-switch {
    height: 34px;
    line-height: 34px;
}

.head_login /deep/ .el-switch__core {
    width: 98px !important;
    height: 34px;
    background: #006a9a;
    box-shadow: inset 0px 1px 2px 0px #054562;
    border-radius: 17px;
    border: none;
}
.head_login /deep/ .el-switch__core:after {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    background: #ffffff;
}
.head_login.person_role::after,
.head_login.company_role::after {
    left: 67px;
    background-color: #ff7600;
    background-color: rgb(0, 49, 7);
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: center;
}
.head_login.company_role::after {
    background-image: url(../../assets/img/head_switch_company_ic.png);
}
.head_login.person_role::after {
    background-image: url(../../assets/img/head_switch_person_ic.png);
}
.head_login.company_role .switch_txt,
.head_login.person_role .switch_txt {
    left: 12px;
}
.switch_txt {
    position: absolute;
    left: 35px;
    top: 10px;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
}
/* 菜单 */
.head_menu_box {
    width: 100%;
    height: 48px;
    background: #fff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.head_menu_list {
    display: flex;
    justify-content: center;
}
.head_menu_list /deep/ .el-radio-button__inner {
    padding: 16px 0px 12px;
}
.head_menu_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #1290c9;
    background-color: #fff;
    border-color: #1290c9;
    box-shadow: none;
}
.head_menu_item /deep/ .el-radio-button__inner {
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    line-height: 18px;
    font-size: 14px;
    color: #022955;
}
.head_menu_item {
    line-height: 20px;
    font-size: 14px;
    /* padding: 18px 30px 10px 30px; */
    text-align: center;
    /* margin: 0 30px; */
    padding: 0 30px;
}
.search_option /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none !important;
}
.top_usage {
    padding: 15px 10px;
    background: #f2f5f9;
    border-radius: 14px;
    margin-bottom: 7px;
}
.usage_prog {
    flex: 1;
}
.usage_ic {
    width: 26px;
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.usage_ic img {
    width: 12px;
    height: 12px;
}
.usage_txt {
    font-size: 14px;
    color: #022955;
    margin-bottom: 8px;
}
.usage_total {
    font-size: 12px;
    color: #8497ab;
}
.usage_used {
    margin-top: 8px;
    font-size: 12px;
    color: #1290c9;
}
.acc_signout,
.top_acc_menu_box li a {
    display: block;
    padding: 8px 10px;
    font-size: 14px;
    color: #022955;
    cursor: pointer;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover,
.top_acc_menu a.router-link-exact-active.router-link-active {
    color: #1290c9;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover {
    background: #ebf2ff;
    border-radius: 14px;
}
.head_login,
.acc_signout {
    cursor: pointer;
}

.el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px !important;
    /* background-color: #fff; */
    margin: auto;
    padding: 0;
}
.head_sign {
    cursor: pointer;
    color: #022955;
    line-height: 50px;
}
.head_top_left {
    width: 162px;
    height: 26px;
    cursor: pointer;
}
    .delase{
        margin-left: 175px;
        font-size: 20px ;
        margin-bottom: 10px;
    }
@media (max-width: 821px) {
    .top_title{
            font-size: 30px;
    }
    .flex_center_between_box1 {
        flex-wrap: wrap;
    }
    .title_blue1{
        margin-top: 80px !important;
    }
    .title_blue{
        font-size: 30px !important;
    }
    .txt_des{
         font-size: 16px !important;
    }
    .title_dack_blue{
        font-size: 30px !important;
    }
    .purpose_left img{
        width: 100% !important;
        display: none;
    }
    .our_purpose{
        display: none;
    }
    .cor_title{
        font-size: 30px !important;
    }
    .date_right img{
        display: none;
    }
    .team_left img{
        display: none;
    }
    .our_team {
        margin: 30px 0px!important;
        height: 460px !important;
    }
 .contact_left, .team_right, .date_left, .story_left {
        width: 100% !important;
        padding: 0px 10px !important;
    }
    .head_login{
background:none !important ;
    }
    .head_login::after{
        height: 0px !important;
    }
    .top_content{
        height: 508px !important;
    }
    .contact_title_txt{
        font-size: 18px !important;
    }
    .delase{
        margin-left: 168px !important;
        font-size: 18px !important;
        margin-bottom: 10px;
    }
}
</style>